import React  from "react"
import styled from "styled-components"
import Slider from "react-slick"
import {Container} from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import LeftArrow from "../../resources/img/home/left-arrow.svg"
import RightArrow from "../../resources/img/home/right-arrow.svg"
import ApostropheImage from "../../resources/img/home/apostrophe.svg"
import BlueLineImage from "../../resources/img/home/blue-line.svg"

const SliderContainer = styled.div`
    background-color: ${colors.lightBlue};
    padding-top: 105px;
    @media(min-width: ${breakpoints.lg}){
        padding-top: 130px;
    }
    
`

const ControllerContainer = styled.div`
    position: absolute;
    right: 1px;
    margin-top: -63px;
`

const CarouselController = styled.img`
    cursor: pointer;
    border: none;
    width: 16px;
    height: 16px;
    padding: 24px;
`

const Previous = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    height: 63px;
    width: 63px;
    display: inline-block;
`

const Next = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    height: 63px;
    width: 63px;
    display: inline-block;
    margin-left: 3px;
`

const Slide = styled.div`
    outline: none;
    border: 0;
    width: 100%;
    margin: auto 33px;
    @media(min-width: ${breakpoints.lg}){
        padding-left: 144px;
        margin: auto;
    }
`

const Apostrophe = styled.img`
    height: 23px;
    width: 26px;
    @media(min-width: ${breakpoints.lg}){
        height: 27px;
        width: 31px;   
    }
`

const BlueLine = styled.img`
    width: 60px;
`

const Content = styled.div`
    ${fonts.jaggerRegular};
    color: ${colors.darkBlue};
    font-size: 24px;
    letter-spacing: -0.69px;
    line-height: 38px;
    padding-top: 25px;
    padding-bottom: 34px;
    width: 80%;
    @media(min-width: ${breakpoints.lg}){
        width: 60%;
    }
`

const Author = styled.div`
    text-transform: uppercase;
    ${fonts.jaggerRegular};
    font-size: 24px;
    letter-spacing: 2.5px;
    padding-top: 45px;
    padding-bottom: 120px;
    @media(min-width: ${breakpoints.xl}){
        padding-bottom: 125px;
    }
`

class CarouselBlock extends React.Component {
  state = {
    slideIndex: 0,
  }

  changeSlide(diff) {
    const slideIndex = this.state.slideIndex + diff

    this.slider.slickGoTo(slideIndex)
  }

  render() {
    const { slides } = this.props

    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: false,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      initialSlide: 0,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    }
    return (
      <SliderContainer>
        <Container>
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {slides.map((slide, index) => {
              return (
                <Slide key={index}>
                  <Apostrophe src={ApostropheImage}/>
                  <Content>{slide.text}</Content>
                  <BlueLine src={BlueLineImage}/>
                  <Author>{slide.author}</Author>
                </Slide>
              )
            })}
          </Slider>
        </Container>
        <ControllerContainer>
          <Previous onClick={() => this.changeSlide(-1)}><CarouselController src={LeftArrow}/></Previous>
          <Next onClick={() => this.changeSlide(1)}><CarouselController src={RightArrow}/></Next>
        </ControllerContainer>
      </SliderContainer>
    )
  }
}

export default CarouselBlock
