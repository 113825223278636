import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/core/Hero"
import get from "lodash/get"
import IntroBlock from "../components/home/Intro"
import FeaturedPosts from "../components/home/FeaturedPosts"
import CarouselBlock from "../components/home/Carousel"
import NumbersBlock from "../components/home/NumbersBlock"
import { withTranslation } from "react-i18next"
import {graphql} from "gatsby";
import GetStartedBlock from "../components/core/GetStartedBlock"

class IndexPage extends React.Component {

  render() {
    const i18n = this.props.i18n
    const path = this.props.location.pathname
    const data = get(this, "props.data.prismic.homepage.edges")
    let introData = []
    let testimonialData = []
    let numbersData = []

    let homepage = data.find((ver) => {
      return ver.node._meta.lang.includes(i18n.language)
    }).node

    let articles
    if (i18n.language === 'fr') {
      articles = get(this, "props.data.prismic.articlesFr.edges")
    } else {
      articles = get(this, "props.data.prismic.articles.edges")
    }

    homepage.body.forEach((data) => {
      if (data.__typename === "PRISMIC_HomeBodyIntro_carousel") {
        introData.push(data.fields)
      }
      if (data.__typename === "PRISMIC_HomeBodyTestimonial_carousel") {
        testimonialData.push(data.fields)
      }
      if (data.__typename === "PRISMIC_HomeBodyNumbers") {
        numbersData.push(data.primary)
      }
    })

    return (
      <Layout meta={{
        uid: 'home',
        lang: i18n.language === 'en' ? 'en-us' : 'fr-fr',
        alternateLanguages:[{ uid: 'home', lang: i18n.language === 'en' ? 'fr-fr': 'en-us' }],
      }}>
        <SEO title={homepage.meta_title}
             description={homepage.meta_description}
             fullTitle={true}
             path={path}/>
        <Hero image={homepage.hero_imageSharp}
              title={homepage.hero_title[0].text}
              content={homepage.hero_intro[0].text}
              top
              large
        />
        <IntroBlock data={introData[0]}/>
        <NumbersBlock data={numbersData[0]}/>
        <CarouselBlock slides={testimonialData[0]}/>
        <FeaturedPosts articles={articles}/>
        <GetStartedBlock extraLarge/>
      </Layout>
    )
  }
}

export default withTranslation()(IndexPage)

export const pageQuery = graphql`
  query homePageQuery {
    prismic {
      homepage : allHomes {
        edges {
          node {
            meta_title
            meta_description
            hero_title
            hero_intro
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid (quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            _meta {
              uid
              tags
              lang
              type
            }
            body {
              ... on PRISMIC_HomeBodyIntro_carousel {
                type
                fields {
                  title
                  content
                  slide_image
                  slide_imageSharp {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomeBodyNumbers {
                type
                label
                primary {
                  title
                  first_number
                  first_text
                  second_number
                  second_text
                  third_number
                  third_text
                }
              }
              ... on PRISMIC_HomeBodyTestimonial_carousel {
                type
                fields {
                  author
                  text
                }
              }
            }
          }
        }
      }
      articles: allArticles(lang: "en-gb", sortBy: published_at_DESC) {
        edges {
          node {
            _meta {
              uid
              tags
              lang
              type
            }
            published_at
            title
            thumbnail_image
            thumbnail_imageSharp {
              childImageSharp {
                fluid(quality: 100, maxWidth: 576, maxHeight: 669) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      articlesFr: allArticles(lang: "fr-fr", sortBy: published_at_DESC) {
        edges {
          node {
            _meta {
              uid
              tags
              lang
              type
            }
            published_at
            title
            thumbnail_image
            thumbnail_imageSharp {
              childImageSharp {
                fluid(quality: 100, maxWidth: 576, maxHeight: 669) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
