import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import CountUp from 'react-countup';
import { Fade } from 'react-reveal';
import { withTranslation } from "react-i18next"

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
`

const WhiteBackground = styled.div`
    background-color: ${colors.white};
    padding-top: 90px;
    padding-bottom: 90px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 160px;
        padding-bottom: 160px;
    }
`

const NumbersTitle = styled.div`
    color: ${colors.darkBlue};
    ${fonts.jaggerRegular};
    font-size: 28px;
    letter-spacing: -0.8px;
    line-height: 42px;
    max-width: 216px;
    @media(min-width: ${breakpoints.xl}){
        padding-left: 144px;
        max-width: 216px;
        padding-bottom: 0;
    }
`

const StyledNumber = styled.div`
    color: ${colors.darkBlue};
    ${fonts.jaggerBold};
    font-size: 80px;
    letter-spacing: 0;
    border-bottom: 2px solid ${colors.blue};
    max-width: 211px;
    padding-bottom: 40px;
`

const Description = styled.div`
    color: ${colors.darkBlue};
    ${fonts.dmSansRegular};
    letter-spacing: 0;
    line-height: 23px;
    font-size: 18px;
    padding-top: 21px;
`

const Box = styled.div`
    margin-top: 56px;
    @media(min-width: ${breakpoints.xl}){
        margin-top: 10px;
    }
`

class NumbersBlock extends Component {
  state = {
    countUpInView: false
  }

  changeVisibility = () => {
    this.setState({
      countUpInView: true
    })
  }

  render() {
    const { data } = this.props
    const { first_number, first_text, second_number, second_text, third_number, third_text, title} = data;

    return (
      <WhiteBackground>
        <Container>
          <RowStyled>
            <Col xl={4}>
              <NumbersTitle>{title}</NumbersTitle>
            </Col>
            <Col xl={8}>
              <Row>
                <Col xl={4}>
                  <Box>
                    <Fade onReveal={() => this.changeVisibility()}>
                      <StyledNumber>
                        <CountUp start={0} end={this.state.countUpInView ? first_number : 0} duration={2} />
                      </StyledNumber>
                    </Fade>
                    <Description>{first_text}</Description>
                  </Box>
                </Col>
                <Col xl={4}>
                  <Box>
                    <StyledNumber>
                      <CountUp start={0} end={this.state.countUpInView ? second_number : 0} duration={2} suffix={"K"} />
                    </StyledNumber>
                    <Description>{second_text}</Description>
                  </Box>
                </Col>
                <Col xl={4}>
                  <Box>
                    <StyledNumber>
                      <CountUp start={0} end={this.state.countUpInView ? third_number : 0} duration={2} suffix={"K"} />
                    </StyledNumber>
                    <Description>{third_text}</Description>
                  </Box>
                </Col>
              </Row>
            </Col>
          </RowStyled>
        </Container>
      </WhiteBackground>
    )
  }
}

export default withTranslation()(NumbersBlock);
