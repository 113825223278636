import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ArticleCard from "../core/ArticleCard"
import { withTranslation } from "react-i18next"
import ArticleCardLarge from "../core/ArticleCardLarge"

const WhiteBackground = styled.div`
    background-color: ${colors.white};
    padding-top: 85px;
    padding-bottom: 43px;
    @media(min-width: ${breakpoints.xl}){
        padding-top: 130px;
        padding-bottom: 128px;
    }
`

const RowStyled = styled(Row)`
    margin: auto 33px;
    @media(min-width: ${breakpoints.md}){
        margin: auto;
    }
`

const Title = styled.div`
    color: ${colors.darkBlue};
    font-size: 28px;
    letter-spacing: -0.8px;
    text-align: center;
    line-height: 42px;
    padding-bottom: 60px;
    ${fonts.jaggerRegular};
    @media(min-width: ${breakpoints.md}){
        padding-bottom: 100px;
    }
`

class FeaturedPosts extends Component {
  render() {
    const { t, articles } = this.props

    let mustReadArticles = []
    let articlesData = []

    articles.map((articleData) => {
      const node = articleData.node

      if (!node.thumbnail_imageSharp) {
        return
      }

      if (mustReadArticles.length < 1
        && node._meta.tags.includes('Must Read Article')) {
        mustReadArticles.push(node);
      } else if (articlesData.length < 4) {
        articlesData.push(node);
      }
    })

    return (
      <WhiteBackground>
        <Container>
          <RowStyled>
            <Col>
              <Title>{t('home.featuredBlogPosts')}</Title>
            </Col>
            <Col xl={6}>
              <ArticleCardLarge article={mustReadArticles[0]} />
            </Col>
            <Col xl={6}>
              <Row>
                {articlesData.map((article, index) => {
                  return (
                    <Col xl={6} key={index}>
                      <ArticleCard article={article}/>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </RowStyled>
        </Container>
      </WhiteBackground>
    )
  }
}

export default withTranslation()(FeaturedPosts);
