import React from 'react';
import styled from 'styled-components';

const OverlayStyled = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: transparent;
`;
const BottomOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(212deg, rgba(241,241,241,0.0) 0%, rgba(7,13,73,1) 90%);
`;

const Overlay = () => {
  return (
    <OverlayStyled>
      <BottomOverlay/>
    </OverlayStyled>
  );
}

export default Overlay;