import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "styled-bootstrap-grid";
import Img from "gatsby-image";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import GetStartedButton from "../core/GetStartedButton";
import Slider from "react-slick/lib"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
`

const BlueBackground = styled.div`
    background-color: ${colors.lightBlue};
    padding-top: 90.3px;
    padding-bottom: 90px;
    outline: none;
    cursor: ${({grab}) => grab ? "grabbing" : "grab"};
    @media(min-width: ${breakpoints.md}){
        padding-top: 130px;
        padding-bottom: 130px;
    }
`

const IntroImage1 = styled(Img)`
    max-width: 350px;
    margin: auto;
    -webkit-box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    -moz-box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    @media(min-width: ${breakpoints.md}){
        max-width: 500px;
    }
    @media(min-width: ${breakpoints.lg}){
        margin-left: 72px;
        max-width: 667px;
    }
`


const GifImage = styled.img`
    max-width: 350px;
    margin: auto;
    -webkit-box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    -moz-box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    @media(min-width: ${breakpoints.md}){
        max-width: 500px;
    }
    @media(min-width: ${breakpoints.lg}){
        margin-left: 72px;
        max-width: 667px;
    }
`;

const Number = styled.div`
    ${fonts.dmSansBold};
    font-size: 11px;
    color: ${colors.darkBlue};
    letter-spacing: 1.5px;
`

const Title = styled.div`
    ${fonts.jaggerRegular};
    color: ${colors.darkBlue};
    font-size: 28px;
    letter-spacing: -0.8px;
    line-height: 42px;
    margin-top: 20px;
    margin-bottom: 30px;
`

const Text = styled.div`
    ${fonts.dmSansRegular};
    color: ${colors.darkBlue};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    padding-bottom: 40px;
`

const QuotationBox = styled.div`
      text-align: center;
      margin: auto;
      padding-top: 81px;
      @media(min-width: ${breakpoints.lg}){
          margin-left: 125px;
          text-align: left;
          margin-bottom: auto;
          padding-top: 0;
      }
      @media(min-width: ${breakpoints.xl}){
          padding-top: 81px;
      }
`


class IntroBlock extends Component { 
  state = {
    slideIndex: 0,
    grab: false
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      initialSlide: 0,
      beforeChange: (current, next) => this.setState({slideIndex: next}),
    };

    const {slideIndex, grab} = this.state;
    const {data: slideContents} = this.props;
    const totalLength = slideContents.length < 10 ? `0${slideContents.length}` : slideContents.length;
    const currentIndex = slideIndex < 10 ? `0${slideIndex + 1}` : slideIndex + 1;
    const carouselIndex = {total: totalLength, current: currentIndex}

    return (
      <div>
        <Slider {...settings} ref={c => (this.slider = c)}>
          {slideContents.map((slide, index) => (
            <BlueBackground key={index} onMouseDown={() => this.setState({grab: true})} onMouseUp={() => this.setState({grab: false})} grab={grab}>
              <Container>
                <RowStyled>
                  <Col lg={7}>
                    {(slide.slide_imageSharp && slide.slide_imageSharp.childImageSharp) ?
                      <IntroImage1 fluid={slide.slide_imageSharp.childImageSharp.fluid} alt={slide.slide_image.alt}/>
                      :
                      slide.slide_image && <GifImage src={slide.slide_image.url} alt={slide.slide_image.alt} />
                    }
                  </Col>
                  <Col lg={5}>
                    <QuotationBox>
                    <Number>{carouselIndex.current}/{carouselIndex.total}</Number>
                      <Title>{slide.title[0].text}</Title>
                      <Text>{slide.content[0].text}</Text>
                      <GetStartedButton />
                    </QuotationBox>
                  </Col>
                </RowStyled>
              </Container>
            </BlueBackground>
          ))}
        </Slider>
      </div>
    )
  }
}

export default IntroBlock;
