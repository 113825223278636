import React from "react";
import styled from "styled-components";
import Overlay from "./Overlay";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import { Container } from "styled-bootstrap-grid";
import Button from "./Button";
import GetStartedButton from "./GetStartedButton";
import Img from "gatsby-image";

const BackgroundStyled = styled.div`
    position: relative;
    height: ${props => props.extraLarge ? "600px" : props => props.large ? "745px": "745px" };
    margin-top: ${props => props.top ? "91px" : "0"};
    @media(min-width: ${breakpoints.lg}){
        height: ${props => props.extraLarge ? "700px" : props => props.large ? "600px": "500px" };
        margin-top: ${props => props.top ? "98px" : "0"};
    }
`;

const ImgStyled = styled(Img)`
    width: 100vw;
    height: ${props => props.extraLarge ? "600px" : props => props.large ? "745px": "745px" };
    @media(min-width: ${breakpoints.lg}){
        height: ${props => props.extraLarge ? "700px" : props => props.large ? "600px": "500px" };
    }
`;

const Title = styled.div`
    color: ${colors.white};
    ${fonts.jaggerRegular};
    border-left: 2px solid ${colors.blue};
    font-size: 42px;
    letter-spacing: -1.35px;
    line-height: 52px;
    max-width: 232px;
    padding-left: 14px;
    @media(min-width: ${breakpoints.md}){
        font-size: 62px;
        letter-spacing: -2px;
        line-height: 72px;
        max-width: 510px;
        padding-left: 18px;
    }
`;

const Description = styled.div`
    color: ${colors.white};
    ${fonts.dmSansRegular};
    font-size: 15px;
    line-height: 23px;
    max-width: 432px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: justify;
`;

const HeroBox = styled.div`
    margin-right: 33px;
    margin-left: 33px;
    padding-bottom: ${props => props.top ? "81px" : "87px"};
    position: absolute;
    bottom: 0;
    z-index: 2;
    @media(min-width: ${breakpoints.lg}){
        padding-bottom: ${props => props.top ? "104px" : "126px"};
        margin-left: ${props => props.top ? "33px" : "73px"};
    }
`;

const Video = styled.video`
    position: relative;
    height: ${props => props.extraLarge ? "600px" : props => props.large ? "745px": "745px" };
    margin-top: ${props => props.top ? "91px" : "0"};
    @media(min-width: ${breakpoints.lg}){
        height: ${props => props.extraLarge ? "700px" : props => props.large ? "600px": "500px" };
        margin-top: ${props => props.top ? "98px" : "0"};
    }
`;

const Hero = ({ image, title, content, ctaLabel, ctaLink, top, large, extraLarge, video }) => {
  return (
    <div>
      <BackgroundStyled top={top} large={large} extraLarge={extraLarge}>
        {video ?
          <Video src={video} large={large} extraLarge={extraLarge} muted={true} loop={true} type="video/mp4"/>
          :
          <ImgStyled fluid={image.childImageSharp.fluid} alt={title} large={large} extraLarge={extraLarge}/>
        }
        <Overlay/>
        <Container>
          <HeroBox top={top}>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Description dangerouslySetInnerHTML={{ __html: content }} />
            {ctaLabel ? (
              <Button to={ctaLink}>
                {ctaLabel}
              </Button>
            ) : (<GetStartedButton />)}
          </HeroBox>
        </Container>
      </BackgroundStyled>
    </div>
  )
}

export default Hero
