import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Hero from "./Hero";
import {useTranslation} from "react-i18next";

const GetStarted = (props) => {
  const contactBlocks = props.data.prismic.contact.edges;
  const { i18n } = useTranslation()

  const contact = contactBlocks.find((ver) => {
    return ver.node._meta.lang.includes(i18n.language)
  }).node

  const { contact_title, contact_text, contact_imageSharp } = contact;

  const htmlTitle = contact_title.replace(/\n/g, "<br />");
  const htmlText = contact_text.replace(/\n/g, "<br />");

  return (
    <Hero image={contact_imageSharp}
          title={htmlTitle}
          content={htmlText}
          extraLarge={true}
          />
  )
}

const staticQuery = graphql`
  query ContactBlockQuery {
    prismic {
      contact:allContacts {
        edges {
          node {
            _meta {
              lang
            }
            contact_text
            contact_title
            contact_image
            contact_imageSharp {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }`

const GetStartedBlock = (props) => (
  <StaticQuery
    query={`${staticQuery}`}
    render={data => <GetStarted {...props} data={data}/>}
  />
)

export default GetStartedBlock;
